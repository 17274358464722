import moment from "moment";

export const monthEngName = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export function getLocalTimeFromTimestamp(d) {
  // .unix(d[0])
  return moment.unix(d).format("YYYY-MM-DD");
}

export function getLocalTimeFromTimestampList(d) {
  // .unix(d[0])
  return d.map((x) => moment.unix(x).format("YYYY-MM-DD"));
}

export function getLocalTimeFromTimestampDMY(d) {
  return moment.unix(d / 1000).format("DD MMM YYYY");
}

export function getLocalTimeFromTimestampDMYHM(d) {
  return moment.unix(d / 1000).format("YYYY-MM-DD HH:mm");
}

export function getLocalUnixNow() {
  return Date.now();
  //return moment().unix();
}

export function getPaddingDateFormat(d) {
  return `${d.getFullYear()}-${(d.getMonth() + 1)
    .toString()
    .padStart(2, "0")}-${d.getDate().toString().padStart(2, "0")}`;
}

export function getUnixTimeFromDate(d) {
  // return Math.floor(d.getTime() / 1000)
  return Math.floor(d.getTime())
}
// export function getLocalTimeFromTimestamp(ts) {
//     return moment(ts).format('hh mm ss')
// }
